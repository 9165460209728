<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <portal to="tabs">
        <v-tabs v-model="tabSelected" background-color="transparent">
          <v-tab @click="$router.push({ path: `/user/client/`})"><v-icon class="pr-2">mdi-account</v-icon>Клиенты</v-tab>
          <v-tab @click="$router.push({ path: `/user/spec/`})"><v-icon class="pr-2">mdi-doctor</v-icon>Специалисты</v-tab>
          <v-tab @click="$router.push({ path: `/user/admin/`})"><v-icon class="pr-2">mdi-account-tie</v-icon>Администраторы</v-tab>
        </v-tabs>
      </portal>
      <div class="px-4">
        <v-text-field
            v-model="filter.usersSearchInput"
            label="Общий поиск"
            dense outlined hide-details="auto"
            clearable
            class="mt-1 mb-4"
            hint="Ф.И.О., телефон, email"
        />
        <v-text-field
            v-model="filter.userId"
            label="ID пользователя"
            dense outlined hide-details="auto"
            clearable
            class="mb-4"
        />
        <v-text-field
            v-model="filter.userOid"
            label="Oid"
            dense outlined hide-details="auto"
            clearable
            class="mb-4"
        />
        <v-select
            label="Пол"
            v-model="filter.userGender"
            :items="[
              {name:'Любой',value:null},
              {name:'Мужчина',value:'male'},
              {name:'Женщина',value:'female'},
            ]"
            item-text="name"
            outlined dense hide-details="auto" class="my-4"
        />
        <label-view title="Возраст">
          <template v-slot:content>
            <v-row class="pt-2 px-1 mb-2">
              <v-col>
                <v-text-field v-model="filter.userYearsFrom" clearable label="С" type="number" hide-details outlined dense/>
              </v-col>
              <v-col>
                <v-text-field v-model="filter.userYearsTo" clearable label="По" type="number" hide-details outlined dense/>
              </v-col>
            </v-row>
          </template>
        </label-view>

        <com-branch-autocomplete-multiple :select.sync="filter.comBranchs" preLoad/>
        <spec-autocomplete-multiple v-if="$router.currentRoute.params.type!=='client'" :select.sync="filter.specs" />

        <date-interval v-if="$router.currentRoute.params.type==='client'" name="Визиты" :valueFrom.sync="filter.valueFrom" :valueTo.sync="filter.valueTo"/>

        
        <checkbox-field name="Активный медплан" class="mb-3" :value.sync="filter.hasMedPlan"/>

        <checkbox-field name="Долг" class="mb-3" :value.sync="filter.hasDebt"/>

        <checkbox-field name="Предоплата" class="mb-4" :value.sync="filter.hasPrepayment"/>

        <v-row class="px-1">
          <v-col sm="12">
            <v-btn small block depressed color="silver" @click="filterReset">
              Очистить фильтры
            </v-btn>
          </v-col>
          <v-col>
            <v-btn small block depressed color="silver" @click="isCreateOpen=true">
              Создать
            </v-btn>
          </v-col>
          <v-col>
            <v-btn small block depressed color="silver"  @click="mergeUsersModal=true">
              Объединить
            </v-btn>
          </v-col>
        </v-row>

      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
          ref="table"
          name="Список"
          :genRequestBuilder="genRequestBuilder"
          :height="height"
          :headers="
            $router.currentRoute.params.type==='client' ? [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Тип', value: 'type', class:'text-no-wrap' },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Мед. карта: филиал / склад / номер', value: 'userProfile.medCardNumber', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ] :
            $router.currentRoute.params.type==='spec' ? [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Специальности', value: 'userSpecs', class:'text-no-wrap', sortable:false },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ] :
            [
              { text: 'ID', value: 'id', class:'text-no-wrap'},
              { text: 'Ф.И.О.', value: 'lastName', class:'text-no-wrap' },
              { text: 'Специальности', value: 'userSpecs', class:'text-no-wrap', sortable:false },
              { text: 'Пол', value: 'userProfile.gender', class:'text-no-wrap' },
              { text: 'Дата рождения', value: 'userProfile.birth', class:'text-no-wrap'},
              { text: 'Телефон', value: 'phone', class:'text-no-wrap'},
              { text: 'Доп. телефон', value: 'userProfile.phone1', class:'text-no-wrap'},
              { text: 'Telegram', value: 'userProfile.telegram', class:'text-no-wrap'},
              { text: 'Email', value: 'userProfile.email', class:'text-no-wrap', width: '100%'},
            ]
          "
      >
        <template v-slot:item="{item}">
          <tr :style="filter.hasDebt&&loadState.isSuccess ? {background:'#FFEBEE'} : filter.hasPrepayment&&loadState.isSuccess ? {background:'#E8F5E9'} : ''" :title="filter.hasDebt&&loadState.isSuccess ?'Есть неоплаченные услуги': null">
            <td class="text-no-wrap" @click="$router.push({ path: `/user/${$router.currentRoute.params.type}/${item.id}/` })">
              {{item.id}}
            </td>
            <td class="text-no-wrap" @click="$router.push({ path: `/user/${$router.currentRoute.params.type}/${item.id}/` })">
              <v-icon v-if="filter.hasDebt&&loadState.isSuccess" class="mr-2" color="red" small>mdi-alert-circle</v-icon> {{item.lastName}} {{item.firstName}} {{item.secondName}}
            </td>
            <td class="text-no-wrap" v-if="$router.currentRoute.params.type==='client'">{{item.type==='client' ? 'Клиент' : item.type==='spec' ? 'Специалист' : 'Администратор'}}</td>
            <td class="text-wrap" v-if="$router.currentRoute.params.type==='spec' || $router.currentRoute.params.type==='admin'">
              <v-sheet width="500" color="transparent">
                <div v-for="(userSpec,index) in item.userSpecs" :key="'userSpec'+index" class="caption">
                  {{userSpec.spec.name}}
                  <v-icon small>mdi-chevron-left</v-icon>
                  {{userSpec.comDiv.comBranch.name}}
                  <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2" size="12">mdi-comment-text-outline</v-icon>
                  {{userSpec.commentAdmin}}

                  <v-icon v-if="userSpec.isExternalSelfVisitCreate" class="ml-2" size="14" color="primary" title="Сторонние сервисы">mdi-remote-desktop</v-icon>
                  <v-icon v-if="userSpec.isSelfVisitCreate" class="ml-2" size="14" color="primary" title="Сайт и моб. приложение">mdi-monitor-cellphone</v-icon>
                  <v-divider v-if="item.userSpecs.length-1>index"/>
                </div>
              </v-sheet>
            </td>
            <td class="text-no-wrap">
              <span v-if="item.userProfile.gender==='male'">мужчина</span>
              <span v-else>женщина</span>
            </td>
            <td class="text-no-wrap">{{$tools.date(item.userProfile.birth)}}</td>
            <td class="text-no-wrap" v-if="$router.currentRoute.params.type==='client'">
              {{$tools.isEmpty(item.userProfile.medCardStore) ? '-' : item.userProfile.medCardStore }} /
              {{$tools.isEmpty(item.userProfile.medCardStorePosition) ? '-' : item.userProfile.medCardStorePosition }} /
              {{$tools.isEmpty(item.userProfile.medCardNumber) ? item.id : item.userProfile.medCardNumber}}
            </td>
            <td class="text-no-wrap">
              <link-view :url="'tel:'+item.phone" :text="item.phone"/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.phone1)">-</span>
              <link-view v-else :url="'tel:'+item.userProfile.phone1" :text="item.userProfile.phone1"/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.telegram)">-</span>
              <link-view v-else :url="'https://t.me/'+item.userProfile.telegram" :text="item.userProfile.telegram" newWindow/>
            </td>
            <td class="text-no-wrap">
              <span v-if="$tools.isEmpty(item.userProfile.email)">-</span>
              <link-view v-else :url="'mailto:'+item.userProfile.email" :text="item.userProfile.email" newWindow/>
            </td>
          </tr>
        </template>
      </n-table-view>
      <UserCreate :is-open.sync="isCreateOpen" @update:isOpen="$refs.table.load()" :userType="$router.currentRoute.params.type"/>


      <dialog-view :open="mergeUsersModal" title="Объединить пользователей" @cancel="mergeUsersModal=false;">
        <template v-slot:content>
          <v-stepper v-model="mergeStep">
            <v-stepper-header>
              <v-stepper-step
                :complete="mergeStep > 1"
                step="1"
              >
              Шаг 1
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="mergeStep > 2"
                step="2"
              >
              Шаг 2
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                Объединить
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                
              <div class="h5">
                Выберите основную карту клиента
              </div>

              <user-autocomplete-single name="Основная карта" :select.sync="toUser" class="my-5"/>
  
                <v-row class="my-5" v-if="toUser">
                  <v-col class="col-auto">
                    <div class="caption">Id:</div>
                    <div>{{ toUser.id }}</div>
                  </v-col>
                  <v-col class="col-auto">
                    <v-divider
                      vertical
                    ></v-divider>
                  </v-col>
                  <v-col class="col-auto">
                    <div class="caption">Дата рождения:</div>
                    <div>{{ $tools.date(toUser.userProfile.birth, 'DD.MM.YYYY') }}</div>
                  </v-col>
                </v-row>

                <v-btn
                  color="primary"
                  :disabled="!toUser"
                  @click="mergeStep = 2"
                >
                  Далее
                </v-btn>


              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="h5">
                  Выберите дубликат карты
                </div>

                <user-autocomplete-single name="Карта донор" :select.sync="fromUser" class="my-5" :exclude="toUser ? [toUser.id] : false"/>
    
                <v-row class="my-5" v-if="fromUser">
                  <v-col class="col-auto">
                    <div class="caption">Id:</div>
                    <div>{{ fromUser.id }}</div>
                  </v-col>
                  <v-col class="col-auto">
                    <v-divider
                      vertical
                    ></v-divider>
                  </v-col>
                  <v-col class="col-auto">
                    <div class="caption">Дата рождения:</div>
                    <div>{{ $tools.date(fromUser.userProfile.birth, 'DD.MM.YYYY') }}</div>
                  </v-col>
                </v-row>
                  
                <v-btn
                  color="primary"
                  :disabled="!fromUser"
                  @click="mergeStep = 3"
                >
                  Далее
                </v-btn>

                <v-btn text @click="mergeStep = 1">
                  Назад
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                
                  <div class="mb-5" v-if="fromUser&&toUser">
                    <v-alert                   
                        outlined
                        type="warning"
                        prominent
                        border="left"
                    >
                      Вы уверены, что хотите объединить карты?
                    </v-alert>
                    <div class="text-center">
                      <v-chip>id:{{ fromUser.id}} {{fromUser.lastName }} {{fromUser.firstName}} {{fromUser.secondName}}</v-chip>
                      <div class="my-3">в</div>
                      <v-chip>id:{{toUser.id}} {{toUser.lastName }} {{toUser.firstName}} {{toUser.secondName}}</v-chip>
                    </div>
                  </div>
                  <div class="text-center my-5">
                    <v-btn
                      color="primary"
                      @click="mergeUsers()"
                      :loading="mergeState.isLoading"
                    >
                      Объединить
                    </v-btn>
                  </div>

                  <v-btn text @click="mergeStep = 2">
                    Назад
                  </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </dialog-view>

      <dialog-view
          title="Удалить пользователя?"
          :open="deleteUserModal"
          @cancel="deleteUserModal = false;this.fromUser = null"
      >
        <template v-slot:content>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            class="mb-0"
            v-if="fromUser"
          >
            <div class="ml-3">
              id:{{ fromUser.id }}<br>
              {{fromUser.lastName }} {{fromUser.firstName}} {{fromUser.secondName}}
            </div>
          </v-alert>
        </template>
        <template v-slot:actions>
          <v-btn text color="primary" @click="deleteUser(fromUser.id);deleteUserModal = false">ОК</v-btn>
        </template>
      </dialog-view>

      
    </template>
  </layout-view>
</template>

<script>
import Api from "@/Api";
import State from "@/plugins/state";
import UserCreate from "@/views/User/UserCreate";
import LayoutView from "@/components/LayoutView";
import LinkView from "@/components/LinkView";
import LabelView from "@/components/LabelView";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import NTableView from "@/componentsV2/base/NTableView.vue";
import DialogView from "@/components/DialogView";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

import {
  Entity_ComBranch, Entity_ComBuilding,
  Entity_ComDiv, Entity_ComFloor, Entity_ComPlace, Entity_ComRoom, Entity_Spec,
  Entity_User,
  Entity_UserProfile,
  Entity_UserSpec, 
  Entity_Visit
} from "../../../EntityStoreCacheService";
import moment from "moment/moment";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import SpecAutocompleteMultiple from "@/componentsV2/custom/SpecAutocompleteMultiple.vue";

export default {
  components: {
    SpecAutocompleteMultiple,
    DateInterval,
    NTableView,
    ComBranchAutocompleteMultiple,
    LabelView, 
    LinkView, 
    LayoutView, 
    UserCreate,
    DialogView,
    UserAutocompleteSingle,
    CheckboxField
  },
  data: () => ({
    tabSelected:0,
    filter: {
      usersSearchInput: null,
      userId:null,
      userOid:null,
      specs:[],
      comBranchs:[],
      userGender:null,
      userYearsFrom:null,
      userYearsTo:null,
      valueFrom: null,
      valueTo: null,
      hasMedPlan:false,
      hasDebt:false,
      hasPrepayment:false,
      page: null,
      onPage: null
    },
    isCreateOpen: false,
    loadState: new State(),
    mergeState: new State(),
    mergeUsersModal: false,
    deleteUserModal: false,
    mergeStep: 1,
    fromUser: null,
    toUser: null,
    firstLoad: true
  }),
  mounted() {
    if (this.$router.currentRoute.params.type === 'client')
      this.tabSelected=0
    else if (this.$router.currentRoute.params.type === 'spec')
      this.tabSelected=1
    else
      this.tabSelected=2

    // Получение JSON-строки из localStorage
    const filterSettings = localStorage.getItem('usersFilters');
    if (typeof localStorage.usersFilters!=='undefined'&&filterSettings!==null)
      this.filter = JSON.parse(filterSettings);
  },
  watch:{
    filter: {
      handler() {
        localStorage.setItem('usersFilters', JSON.stringify(this.filter));
        this.$refs.table.load();
      },
      deep: true,
    },
    'filter.hasDebt'(v) {
      this.loadState.stateLoading();
      if (v)
      this.filter.hasPrepayment=false;
    },
    'filter.hasPrepayment'(v){
      this.loadState.stateLoading();
      if (v)
      this.filter.hasDebt=false;
    }
  },
  methods: {
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_User()
          .selects(Entity_User.id)
          .selects(Entity_User.lastName)
          .selects(Entity_User.firstName)
          .selects(Entity_User.secondName)
          .selects(Entity_User.phone)
          .selects(Entity_User.type)
          .selects([Entity_UserProfile.entity, Entity_UserProfile.birth].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.gender].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.email].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.phone1].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardStore].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardStorePosition].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.medCardNumber].join('.'))
          .selects([Entity_UserProfile.entity, Entity_UserProfile.telegram].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.commentAdmin].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.isExternalSelfVisitCreate].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.isSelfVisitCreate].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch, Entity_ComBranch.name].join('.'))
          .selects([Entity_User.userSpecs, Entity_UserSpec.spec, Entity_Spec.name].join('.'));
          //.selects([Entity_UserProfile.entity, Entity_UserProfile.birth].join('.'))
          //.filterAnd([Entity_User.entity, Entity_User.user,Entity_User.id].join('.')+'=?', this.$router.currentRoute.params.id

          //filter sync 
          if (!this.firstLoad) {
            this.filter.page = page
            this.filter.onPage = onPage
          }

          rb.page(this.filter.page ? this.filter.page : page)
          rb.onPage(this.filter.onPage ? this.filter.onPage :onPage);
          this.firstLoad = false
      
          if (this.$router.currentRoute.params.type==='spec')
            rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+'=?', 'spec')
          else if (this.$router.currentRoute.params.type==='admin')
            rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+'=?', 'admin')

          if(!this.$tools.isEmpty(this.filter.usersSearchInput)) {
            let v = this.filter.usersSearchInput.trim();

          if (v.includes('@')) {
            rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.email].join('.') + ' LIKE ?', '%' + v + '%')
          } else if (/\d/.test(v)) {
            rb.filterAnd(
                [Entity_User.entity, Entity_User.phone].join('.') + ' LIKE ? OR ' +
                [Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.phone1].join('.') + ' LIKE ?'
                ,
                '%' + v.replace(/\D/g, ''),
                '%' + v.replace(/\D/g, '')
            )
          } else {
            let arr = v.split(' ');
            if (arr.length === 1) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
            } else if (arr.length === 2) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
            } else if (arr.length === 3) {
              rb.filterAnd([Entity_User.entity, Entity_User.lastName].join('.') + ' LIKE ?', '%' + arr[0] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.firstName].join('.') + ' LIKE ?', '%' + arr[1] + '%')
              rb.filterAnd([Entity_User.entity, Entity_User.secondName].join('.') + ' LIKE ?', '%' + arr[2] + '%')
            }
          }
        }

        if(!this.$tools.isEmpty(this.filter.userId))
          rb.filterAnd([Entity_User.entity, Entity_User.id].join('.')+' = ?', this.filter.userId)

        if(!this.$tools.isEmpty(this.filter.userOid))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.oid].join('.')+' = ?', this.filter.userOid)

        if(!this.$tools.isEmpty(this.filter.userGender))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.gender].join('.')+' = ?', this.filter.userGender)

        if(this.$router.currentRoute.params.type==='client'){
          if(!this.$tools.isEmpty(this.filter.valueFrom))
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.begin].join('.')+' >= ?', moment(this.filter.valueFrom, 'DD.MM.YYYY').set({hour:0,minute:0,second:0}))
          if(!this.$tools.isEmpty(this.filter.valueTo))
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.begin].join('.')+' < ?', moment(this.filter.valueTo, 'DD.MM.YYYY').set({hour:0,minute:0,second:0}))
        }

        if(typeof this.filter.comBranchs!=='undefined'&&this.filter.comBranchs.length>0) {
          if(this.$router.currentRoute.params.type==='client'){
            let arr = [];
            this.filter.comBranchs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.visits, Entity_Visit.comPlace, Entity_ComPlace.comRoom,
              Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id
            ].join('.') + ' IN (?)', arr)
            rb.filterAnd([Entity_User.entity, Entity_User.visits, Entity_Visit.comPlace, Entity_ComPlace.comRoom,
              Entity_ComRoom.comFloor, Entity_ComFloor.comBuilding, Entity_ComBuilding.comBranch, Entity_ComBranch.id,
            ].join('.') + ' IS NOT NULL')
          } else {
            let arr = [];
            this.filter.comBranchs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.comDiv,
              Entity_ComDiv.comBranch, Entity_ComBranch.id
            ].join('.') + ' IN (?)', arr)
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.comDiv,
              Entity_ComDiv.comBranch, Entity_ComBranch.id
            ].join('.') + ' IS NOT NULL')
          }
        }

        if(this.filter.specs.length>0) {
          if(this.$router.currentRoute.params.type!=='client'){
            let arr = [];
            this.filter.specs.forEach(v1=>{
              arr.push(v1.id);
            })
            rb.filterAnd([
              Entity_User.entity, Entity_User.userSpecs, Entity_UserSpec.spec,
              Entity_Spec.id
            ].join('.') + ' IN (?)', arr)
          }
        }


        if(!this.$tools.isEmpty(this.filter.userYearsFrom))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.birth].join('.') + ' < ?', moment().set({hour:0,minute:0,second:0}).subtract(this.filter.userYearsFrom, 'years'))
        if(!this.$tools.isEmpty(this.filter.userYearsTo))
          rb.filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.birth].join('.') + ' > ?', moment().set({hour:23,minute:59,second:59}).subtract(this.filter.userYearsTo, 'years'))


        if (this.filter.hasMedPlan) {
          rb.filterAdd(['medPlanCount'].join('.'))
        }
        if (this.filter.hasDebt) {
          rb.filterAdd(['debtor'].join('.'))
        }
        if (this.filter.hasPrepayment) {
          rb.filterAdd(['prepaid'].join('.'))
        }

        rb.addStateCallback((v)=>{
          this.loadState.setRequestBuilderState(v)
        })

          
        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })

        return rb;
    },
    // searhUserById(id,type){
    //   new Entity_User()
    //   .selects(Entity_User.id)
    //   .selects(Entity_User.firstName)
    //   .selects(Entity_User.lastName)
    //   .selects(Entity_User.secondName)
    //   .selects(Entity_User.type)
    //   .selects([Entity_User.userProfile,Entity_UserProfile.gender].join('.'))
    //   .selects([Entity_User.userProfile,Entity_UserProfile.birth].join('.'))
    //   .filterAnd([Entity_User.entity,Entity_User.id].join('.')+'=?',parseInt(id))
    //   .request((r)=>{
    //     if(type==='toUser') this.toUser = r.items[0]
    //     else this.fromUser = r.items
    //   }, (e)=>{
    //     console.error(e.response.data.message);
    //   })
    // },
    mergeUsers() {
      let data = {
        'fromUserId':this.fromUser.id,
        'toUserId':this.toUser.id
      };
      this.mergeState.stateLoading();
      Api.service.user.merge(data, (data)=>{
        this.mergeUsersModal = false
        this.mergeStep = 1
        this.toUser = null
        this.deleteUserModal = true

        this.$store.commit('alertSuccess', data.message);
        this.mergeState.stateSuccess();
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
        this.mergeState.stateError();
      });
    },
    deleteUser(id) {
      Api.service.user.delete(id, ()=>{
        this.fromUser = null;
        this.$store.commit('alertSuccess', 'Успешно удалено');
        this.$refs.table.load();
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
      });
    },
    filterReset() {
      this.filter = {
        usersSearchInput: null,
        userId:null,
        userOid:null,
        specs:[],
        userGender:null,
        userYearsFrom:null,
        userYearsTo:null,
        valueFrom: null,
        valueTo: null,
        hasMedPlan:false,
        hasDebt:false,
        hasPrepayment:false,
        page: null,
        onPage: null
      }
    }
  }
}
</script>


